<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="nav-tab-heading">
          Workspace-Mitglieder
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div
      class="px-1 py-5 text-center"
      style="font-size: 14px"
      v-if="users.length === 0"
    >
      Es sind bisher noch<br />
      keine Teammitglieder beigetreten
    </div>

    <navigation-user-list-item
      v-for="user in users"
      :key="user.id"
      :user="user"
      @delete="onDeleteConfirm"
    ></navigation-user-list-item>

    <delete-user-dialog
      :user="userToDelete"
      @cancel="userToDelete = null"
      @submit="onDeleteSubmit"
    ></delete-user-dialog>
  </v-list>
</template>

<script>
import api from "@/api";

export default {
  name: "NavigationUserList",
  components: {
    DeleteUserDialog: () => import("@/components/DeleteUserDialog"),
    NavigationUserListItem: () => import("./NavigationUserListItem"),
  },
  data: () => ({
    userToDelete: null,
  }),
  computed: {
    users() {
      return this.$store.getters["users/others"];
    },
  },
  methods: {
    onDeleteConfirm(user) {
      this.userToDelete = user;
    },
    onDeleteSubmit() {
      const { id } = this.userToDelete;
      console.log("deleting user", id);
      api.deleteUser(id).then(() => {
        this.$store.dispatch("users/load");
        this.userToDelete = null;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.nav-tab-heading {
  color: $color-primary;
  margin: 14px 0;
}

.theme--dark {
  &.v-list-item {
    padding: 0 6px;
  }
}
</style>